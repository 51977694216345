<template>
  <div id="tabbar">
    <van-row gutter="0" class="tabbar-container">
      <van-col
        span="4"
        class="tab-item"
        v-for="(item, index) in menus"
        :key="index"
        @click.once="onMenuClick(item)"
      >
        <svg-icon
          :data_iconName="active == index ? item.activeicon : item.icon"
          :className="active == index ? 'tab_icon_active' : 'tab_icon'"
        />
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { Toast } from "vant";
import cookies from "../../utils/cookies";
export default {
  name: "Tarbar",
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user:[],
      company:{},
      menus: [
        {
          text: "个人中心",
          icon: "profile-default",
          activeicon: "profile-active",
          path: "/profile",
        },
        {
          text: "导游管理",
          icon: "guide-default",
          activeicon: "guide-active",
          path: "/guider",
        },
        {
          text: "行程管理",
          icon: "trip-default",
          activeicon: "trip-active",
          path: "/trip",
        },
        {
          text: "出团管理",
          icon: "tuan-default",
          activeicon: "tuan-active",
          path: "/tuan",
        },
        {
          text: "事件通知",
          icon: "message-default",
          activeicon: "message-active",
          path: "/message",
        },
        {
          text: "反馈管理",
          icon: "issue-default",
          activeicon: "issue-active",
          path: "/feedbacks",
        },
        // {
        //   text: "培训资料",
        //   icon: "issue-default",
        //   activeicon:"issue-active",
        //   path: "/issue/list",
        // },
      ],
      companyStatus:cookies.get('companyStatus')
    };
  },
  components: {},
  methods: {
    onMenuClick(item) {
      if (item.text == "培训资料") {
        Toast("暂无开放");
        return false;
      }
      if (item.text != "个人中心" && this.companyStatus == "0") {
        Toast("您的账号还未审核，暂无操作权限！");
        return false;
      }
      this.$router.replace(item.path);
    },
    async get_current_company() {
      const res = await this.$apis.get_current_company();
     
      this.user = res.data.user;
      this.company = res.data.company;
      
    },
  },
  created(){
    // this.get_current_company()
  }
};
</script>

<style>
#tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.tabbar-container {
  background: #fff;
  height: 1.2rem;
  width: 100%;
  line-height: 1.4rem;
  box-shadow: 0 -2px 14px 0 rgba(182, 179, 179, 0.64);
  border-radius: 0.6rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.tab-item {
  text-align: center;
}
.tab_icon {
  font-size: 0.8rem;
}
.tab_icon_active {
  font-size: 0.9rem;
}
</style>
